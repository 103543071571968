<template lang="pug">
  AppButton.short(
    :class="`btn-${type}`"
    v-bind="$props"
    v-on="$listeners"
  )
    template(v-slot:[slotName])
      AppIcon(
        v-if="useAppIcon"
        :name="icon"
      )
      FaIcon.icon(
        v-else
        :icon="icon"
      )
</template>

<script>
  const ALLOWED_TYPES = ["add-new", "download", "other"]

  export default {
    props: {
      name: String,
      disabled: Boolean,
      title: String,
      icon: {
        type: String,
        required: true
      },
      type: {
        type: String,
        required: true,
        validator: value => {
          return ALLOWED_TYPES.includes(value)
        }
      },
      useAppIcon: {
        type: Boolean,
        default: false
      },
      iconBefore: {
        type: Boolean,
        default: true
      }
    },

    components: {
      AppButton: () => import("@/components/elements/AppButton"),
      AppIcon: () => import("@/components/elements/AppIcon")
    },

    computed: {
      slotName() {
        if (this.iconBefore) {
          return "before"
        }
        return "after"
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  ::v-deep
    .app-button
      &-content
        height: 21px

        .icon
          margin-right: 5px
</style>
